<template>
  <div class="con">
    <h1>Nav</h1>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, onUnmounted } from "vue";

export default {
  name: "su-niceui-nav",
  components: {},
  props: {},
  setup(props, context) {
    const state = reactive({
      tmp: "",
    });

    onMounted(() => {});

    onUnmounted(() => {});

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
